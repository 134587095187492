import * as qs from 'query-string';

/**
 * Supprime les accents d'une string
 * @param {string} str - string où l'on veut supprimer les accents
 * @returns {string} la string sans accent
 */
export function RemoveAccents(str) {
    var map = {
        'a' : 'á|à|ã|â',
        'A' : 'À|Á|Ã|Â',
        'e' : 'é|è|ê',
        'E' : 'É|È|Ê',
        'i' : 'í|ì|î',
        'I' : 'Í|Ì|Î',
        'o' : 'ó|ò|ô|õ',
        'O' : 'Ó|Ò|Ô|Õ',
        'u' : 'ú|ù|û|ü',
        'U' : 'Ú|Ù|Û|Ü',
        'c' : 'ç',
        'C' : 'Ç',
        'n' : 'ñ',
        'N' : 'Ñ'
    };
    
    for (var pattern in map) {
        str = str.replace(new RegExp(map[pattern], 'g'), pattern);
    };

    return str;
}

export function IsSourceFromWebview(){

    if (typeof window !== 'undefined'){
        if (qs.parse(window.location.search, { ignoreQueryPrefix: true }).source === "webview") // Use for the previous applicatin mobile version - To delete when deployment will be completed
            return true;
        else if (localStorage.getItem('webview') === "true"){ // Check in the local storage if the source is from webview
            return true;
        }
    } 
    
    return false;
    
}

