class ConstantesInformationsLegales {
    static urlCodeEnPoche = process.env.GATSBY_HOST_PROD + "/"
    static nomDeSociete = "Codenup SARL"
    static formeJuridiqueSociete = "Société à responsabilité limitée"
    static siret = "89516141200013"
    static RCS = "895161412" // RCS = SIREN
    static adresseCodeEnPoche = "4 rue du Pensionnat Notre Dame de France 43000 Le-Puy-en-Velay"
    static telephoneCodeEnPoche = "07 66 23 39 83"
    static emailCodeEnPoche = "contact@codeenpoche.fr"
    static responsablePublication = "Depardieu Sébastien"
    static emailResponsablePublication = "contact@codeenpoche.fr"
    static webmaster = "Moreau Diane"
    static emailWebmaster = "contact@codeenpoche.fr"

    static nomHebergeur = "OVH"
    static adressePostaleHebergeur = "2 rue Kellermann - 59100 Roubaix - France"
}
export default ConstantesInformationsLegales;