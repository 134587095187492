import React, { useEffect } from 'react'
import logo_code_en_poche_blanc from "../images/code-en-poche_picto.png"
import { Link, navigate } from "gatsby"
import { Urls } from "src/utils/UrlsConst"
import ConstantesInformationsLegales from "src/legal/ConstantesInformationsLegales"
import { decode, encode } from 'base-64'
import Trustbox from "src/utils/Trustbox.js"
import { Helmet } from "react-helmet";

// Le footer du site web
const Footer = ({ ...props }) => {

  useEffect(() => {

    try {
      var elementText = document.getElementsByClassName("obflink_info_legales")

      for (let i = 0; i < elementText.length; i++) { // We have to add an event listener on each breadcrumb elements in order to catch the click
        let urlBase64 = elementText[i].getAttribute("data-o")
        let urlUtf8 = decode(urlBase64)

        if (elementText[i].getAttribute('listener') !== 'true') {
          elementText[i].setAttribute('listener', 'true');

          elementText[i].addEventListener('click', function () {
            openUrl(urlUtf8);
          }, false);
        }
      }

      function openUrl(url) {
        navigate(url)
      };
    } catch {

    }

  });


  let base64data_cgv = encode(Urls.CGV)
  let base64data_mentions = encode(Urls.MENTIONS_LEGALES)
  let base64data_confidentialite = encode(Urls.POLITIQUE_DE_CONFIDENTIALITE)
  let base64data_cookies = encode(Urls.POLITIQUE_UTILISATION_DES_COOKIES)

  return (
    <footer style={props.styleBlur}>
      <Helmet>
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
      </Helmet>
      <div className="footer-container">
        <div className="footer-horizontal-block">
          <div className="footer-block footer-logo">
            <Link className="logo-menu-link" id="footer-logo-cep" to="/">
              <img src={logo_code_en_poche_blanc} alt="Logo Code en Poche" />
              <div className="brand-content">
                <div className="brand-title text-white">
                  Code en poche
                </div>
                <div className="brand-slogant text-white">
                  contact@codeenpoche.fr
                </div>
                <div className="brand-slogant text-white">
                  © 2019-{(new Date().getFullYear())} {ConstantesInformationsLegales.nomDeSociete}
                </div>
              </div>
            </Link>
            <Trustbox templateId="53aa8807dec7e10d38f59f32" height="150px" theme="dark"/>
          </div>
          <div className="footer-block">
            <p>Apprendre</p>
            <ul>
              <li><Link to={Urls.COURS_DE_CODE}>Cours de Code</Link></li>
              <li><Link to={Urls.TESTS_CODE_DE_LA_ROUTE}>Tests Code de la route gratuits</Link></li>
              <li><Link to={Urls.APPLICATION_CODE_DE_LA_ROUTE}>Application mobile du Code de la route</Link></li>
            </ul>
            {props.isHomePage ?
              <>
                <p>À propos</p>
                <ul><li><Link to={Urls.PLAN_DU_SITE}>Plan du site</Link></li></ul>
              </>
              : null}
          </div>
          <div className="footer-block">
            <p>Informations légales</p>
            <ul>
              {props.isHomePage ?
                <>
                  <li><Link to={Urls.CGV}>CGV</Link></li>
                  <li><Link to={Urls.MENTIONS_LEGALES}>Mentions légales</Link></li>
                  <li><Link to={Urls.POLITIQUE_DE_CONFIDENTIALITE}>Politique de confidentialité</Link></li>
                  <li><Link to={Urls.POLITIQUE_UTILISATION_DES_COOKIES}>Politique d'utilisation des cookies</Link></li>
                </>
                :
                <>
                  <li><span className="obflink_info_legales" data-o={base64data_cgv}>CGV</span></li>
                  <li><span className="obflink_info_legales" data-o={base64data_mentions}>Mentions légales</span></li>
                  <li><span className="obflink_info_legales" data-o={base64data_confidentialite}>Politique de confidentialité</span></li>
                  <li><span className="obflink_info_legales" data-o={base64data_cookies}>Politique d'utilisation des cookies</span></li>
                </>}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer
